<template>
  <v-container>
    <v-form @submit.prevent="search" ref="form">
      <v-row align="center">
        <v-col cols="12" sm="1" class="text-center">
          <b>เงื่อนไข</b>
        </v-col>
        <v-col cols="12" sm="11" class="pr-15">
          <v-row>
            <v-col cols="12" sm="8">
              <v-row align="center">
                <v-col cols="12" sm="2"
                  ><b>ช่วงเวลา</b> <req v-if="!$store.getters.isExecutive"></req
                ></v-col>
                <v-col cols="12" sm="4">
                  <v-menu
                    ref="menuStart"
                    v-model="menuStart"
                    :close-on-content-click="false"
                    :return-value.sync="dateStart"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="pa-0 ma-0 mt-8"
                        v-model="dateStartTh"
                        label="เดือน/ปีเริ่มต้น"
                        append-icon="mdi-calendar"
                        solo
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(v) => !!v || 'กรุณาระบุ เดือน/ปีเริ่มต้น']"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateStart"
                      type="month"
                      no-title
                      scrollable
                      locale="th"
                      :max="new Date().toISOString().substr(0, 10)"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuStart = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuStart.save(dateStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                -
                <v-col cols="12" sm="4">
                  <v-menu
                    ref="menuEnd"
                    v-model="menuEnd"
                    :close-on-content-click="false"
                    :return-value.sync="dateEnd"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="pa-0 ma-0 mt-8"
                        v-model="dateEndTh"
                        label="เดือน/ปีสิ้นสุด"
                        append-icon="mdi-calendar"
                        solo
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(v) => !!v || 'กรุณาระบุ เดือน/ปีสิ้นสุด']"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateEnd"
                      type="month"
                      no-title
                      scrollable
                      locale="th"
                      :max="new Date().toISOString().substr(0, 10)"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuEnd = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuEnd.save(dateEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              class="text-right d-flex align-center flex-row"
            >
              <v-row>
                <v-col cols="12" sm="6"> <b></b></v-col>
                <v-col cols="12" sm="6">
                  <v-btn
                    class="py-6 px-10"
                    block
                    rounded
                    color="app-theme"
                    dark
                    style="text-transform: none !important"
                    type="submit"
                  >
                    <b> ค้นหา</b>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="1" class="text-center">
          <b></b>
        </v-col>
        <v-col cols="12" sm="11" class="pr-15">
          <v-row>
            <v-col cols="12" sm="8">
              <v-row align="center">
                <v-col cols="12" sm="2"><b>เลือก Yard</b></v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    hide-details="auto"
                    color="app-theme"
                    placeholder="เลือก"
                    v-model="yardId"
                    :items="ddlYard"
                    solo
                  >
                  </v-select>
                </v-col>
                <b v-if="$store.getters.isExecutive">เลือกลูกค้า</b>
                <b v-if="!$store.getters.isExecutive">เลือกยี่ห้อรถ</b>
                <v-col cols="12" sm="4" v-if="$store.getters.isExecutive">
                  <v-select
                    hide-details="auto"
                    color="app-theme"
                    placeholder="เลือกลูกค้า"
                    v-model="companyId"
                    :items="ddlCompany"
                    solo
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4" v-if="!$store.getters.isExecutive">
                  <v-select
                    hide-details="auto"
                    color="app-theme"
                    placeholder="เลือกยี่ห้อรถ"
                    v-model="brandId"
                    :items="ddlBrand"
                    solo
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              class="text-right d-flex align-center flex-row"
            >
              <v-row>
                <v-col cols="12" sm="12">
                  <v-btn
                    class="py-6 mr-2"
                    :color="chartBarActive ? 'app-theme' : 'white'"
                    style="text-transform: none !important"
                    @click="searchChart(true)"
                  >
                    <v-icon
                      :color="chartBarActive ? 'white' : 'app-theme'"
                      x-large
                      >mdi-chart-bar
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="py-6"
                    :color="chartLineActive ? 'app-theme' : 'white'"
                    style="text-transform: none !important"
                    @click="searchChart(false)"
                  >
                    <v-icon
                      :color="chartLineActive ? 'white' : 'app-theme'"
                      x-large
                      >mdi-chart-line
                    </v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center" v-if="$store.getters.isExecutive">
        <v-col cols="12" sm="1" class="text-center">
          <b></b>
        </v-col>
        <v-col cols="12" sm="11" class="pr-15">
          <v-row>
            <v-col cols="12" sm="8">
              <v-row align="center">
                <v-col cols="12" sm="2"><b>เลือกยี่ห้อรถ</b></v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    hide-details="auto"
                    color="app-theme"
                    placeholder="เลือกยี่ห้อรถ"
                    v-model="brandId"
                    :items="ddlBrand"
                    solo
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-row v-show="chartBarActive">
      <v-col cols="12">
        <v-card class="ma-6">
          <v-row class="my-1">
            <v-col cols="12" sm="12" class="pr-15">
              <v-row>
                <v-col cols="12" sm="8">
                  <v-row>
                    <v-col cols="12" sm="12" class="ml-10 mt-10"
                      ><h2 style="color: #203486">
                        {{ chartName }}
                      </h2></v-col
                    >
                  </v-row>
                </v-col>

                <v-col cols="12" sm="4" class="text-right">
                  <v-row>
                    <v-col cols="12" sm="6"> <b></b></v-col>
                    <v-col cols="12" sm="6"> </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0" justify="end">
            <v-col
              cols="12"
              sm="4"
              class="mr-4 pa-0 d-flex align-end flex-column"
            >
              <v-btn
                justify="end"
                class="py-6"
                rounded
                color="#f3b420"
                dark
                style="text-transform: none !important"
                @click="downloadExcel"
              >
                <b
                  ><v-icon md>mdi-tray-arrow-down</v-icon> ดาวน์โหลดเป็น
                  Excel</b
                >
              </v-btn>
            </v-col>
          </v-row>
          <div id="chart">
            <apexchart
              ref="chart"
              height="600px"
              type="bar"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="chartLineActive">
      <v-col cols="12">
        <v-card class="ma-6">
          <v-row class="my-1">
            <v-col cols="12" sm="12" class="pr-15">
              <v-row>
                <v-col cols="12" sm="8">
                  <v-row>
                    <v-col cols="12" sm="12" class="ml-10 mt-10"
                      ><h2 style="color: #203486">
                        {{ chartName }}
                      </h2></v-col
                    >
                  </v-row>
                </v-col>

                <v-col cols="12" sm="4" class="text-right">
                  <v-row>
                    <v-col cols="12" sm="6"> <b></b></v-col>
                    <v-col cols="12" sm="6"> </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0" justify="end">
            <v-col
              cols="12"
              sm="4"
              class="mr-4 pa-0 d-flex align-end flex-column"
            >
              <v-btn
                justify="end"
                class="py-6"
                rounded
                color="#f3b420"
                dark
                style="text-transform: none !important"
                @click="downloadExcel"
              >
                <b
                  ><v-icon md>mdi-tray-arrow-down</v-icon> ดาวน์โหลดเป็น
                  Excel</b
                >
              </v-btn>
            </v-col>
          </v-row>
          <div id="chart">
            <apexchart
              ref="chartLine"
              height="600px"
              type="line"
              :options="chartOptionsLine"
              :series="seriesLine"
            ></apexchart>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import helper from "@/helpers/helper";
export default {
  data: function () {
    return {
      helper: helper,
      chartName: "Stock Report",
      chartBarActive: false,
      chartLineActive: false,
      monthTh: [
        "ม.ค.",
        "ก.พ.",
        "ม.ค.",
        "ม.ย.",
        "พ.ค.",
        "ม.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],

      yardId: null,
      brandId: null,
      companyId: null,
      ddlYard: [],
      ddlBrand: [],

      ddlCompany: [],
      dateStart: null,
      dateStartTh: null,
      menuStart: false,
      dateEnd: null,
      dateEndTh: null,
      menuEnd: false,

      categories: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      series: [],
      seriesLine: [],
      chartOptions: {
        colors: ["#74d6dc", "#fbaca9"],
        chart: {
          toolbar: { show: false },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 4,
          colors: ["transparent"],
        },
        legend: {
          fontSize: "28px",
          itemMargin: {
            horizontal: 10,
            vertical: 20,
          },
          position: "top",
          horizontalAlign: "right",
          showForSingleSeries: true,
          markers: {
            radius: 25,
            width: 18,
            height: 18,
            strokeWidth: 0,
          },
        },
        yaxis: {
          tickAmount: 9,
          // max: 4500,
          min: 0,
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          shared: true,
          intersect: false,
          enabledOnSeries: true,
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
            formatter: function (val, opts) {
              return `${val}    ${
                opts.w.globals.seriesNames[opts.seriesIndex]
              }`;
            },
          },
        },
      },
      chartOptionsLine: {
        colors: ["#74d6dc", "#fbaca9"],
        chart: {
          toolbar: { show: false },
        },
        markers: {
          size: 3,
          strokeOpacity: 1,
          strokeWidth: 0,
          hover: {
            size: undefined,
            sizeOffset: 2,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          curve: "straight",
        },
        legend: {
          fontSize: "28px",
          itemMargin: {
            horizontal: 10,
            vertical: 20,
          },
          position: "top",
          horizontalAlign: "right",
          showForSingleSeries: true,
          markers: {
            radius: 25,
            width: 18,
            height: 18,
            strokeWidth: 0,
          },
        },
        yaxis: {
          tickAmount: 9,
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          shared: true,
          intersect: false,
          enabledOnSeries: true,
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
            formatter: function (val, opts) {
              return `${val}    ${
                opts.w.globals.seriesNames[opts.seriesIndex]
              }`;
            },
          },
        },
      },
      colors: [
        "#74d6dc",
        "#fbaca9",
        "#af9ae1",
        "#e6b42b",
        "#8daefa",
        "#e483b0",
        "#b3ebe1",
        "#ff000",
        "#00ff00",
        "#0000ff",
      ],
    };
  },
  watch: {
    dateEnd() {
      this.validateDate();
    },
    dateStart() {
      const month = new Date(this.dateStart).getMonth();
      const year = new Date(this.dateStart).getFullYear();
      this.dateStartTh = `${this.monthTh[month]} ${year}`;
    },
  },
  mounted() {
    this.$store.state.title = "Stock Report";
    this.chartOptions.xaxis.categories = this.categories;
    this.chartOptionsLine.xaxis.categories = this.categories;
    this.getYard();
    this.getBrand();
    this.getCompany();
    if (this.$store.getters.isExecutive) this.getCompany();
  },
  methods: {
    validateDate() {
      const month = new Date(this.dateEnd).getMonth();
      const year = new Date(this.dateEnd).getFullYear();
      if (this.dateStart) {
        const [sYear, sMonth] = this.dateStart.split("-");
        const [eYear, eMonth] = this.dateEnd.split("-");
        const y = Number(eYear) - Number(sYear);
        if (y == 0 || y == 1 || y == -1) {
          if (y == 1 && eMonth >= sMonth) {
            this.errorDate("valid");
          } else if (y == -1) {
            this.errorDate("validEndMonth");
            return false;
          } else if (y == 0 && eMonth < sMonth) {
            this.errorDate("validEndMonth");
          } else {
            this.dateEndTh = `${this.monthTh[month]} ${year}`;
            return true;
          }
        } else {
          this.errorDate("valid");
        }
      } else {
        this.dateEndTh = `${this.monthTh[month]} ${year}`;
        return true;
      }
    },
    errorDate(status) {
      if (status == "valid") {
        this.$root.getErrorSystemMessage(
          "ไม่สามารถเลือกช่วงเวลาเริ่มต้นและสิ้นสุดได้เกิน 12 เดือน"
        );
      } else {
        this.$root.getErrorSystemMessage(
          "ไม่สามารถเลือกช่วงเวลาสิ้นสุดได้น้อยกว่าช่วงเวลาเริ่มต้น"
        );
      }
      return false;
    },
    getYard() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "user/data/yards",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlYard = resp.data.data;
          this.ddlYard = [{ text: "ทั้งหมด", value: null }];
          if (ddlYard) {
            const entity = ddlYard.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });

            this.ddlYard = this.ddlYard.concat(entity);
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getBrand() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "user/data/car-brands",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          if (resp.data.errors) {
            this.$root.getErrorSystemMessage(resp.data.errors);
          } else {
            const ddlBrand = resp.data.data;
            this.ddlBrand = [{ text: "ทั้งหมด", value: null }];
            if (ddlBrand) {
              const entity = ddlBrand.map((item) => {
                return {
                  text: item.name,
                  value: item.id,
                };
              });
              this.ddlBrand = this.ddlBrand.concat(entity);
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    getCompany() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "user/data/user-company",
          params: { page: 1, pageSize: 999 },
        })
        .then((resp) => {
          const ddlCompany = resp.data.data;
          if (ddlCompany) {
            this.ddlCompany = ddlCompany.map((item) => {
              return {
                text: item.name,
                value: item.id,
              };
            });
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
    downloadExcel() {
      let url = "user/report/stock/search";
      if (this.$store.getters.isExecutive) url = "user-ex/report/stock/search";
      if (!this.$refs.form.validate()) return;
      if (!this.chartBarActive && !this.chartLineActive) {
        this.chartBarActive = true;
        this.$forceUpdate();
      }
      // this.$store.state.loading = true;
      const formData = new FormData();

      formData.append("dateSt", this.dateStart);
      formData.append("dateEnd", this.dateEnd);

      if (this.yardId) formData.append("yardId", this.yardId);
      if (this.companyId) formData.append("userCompanyId", this.companyId);
      if (this.brandId) formData.append("carBrandId", this.brandId);

      formData.append("download", true);
      this.$root
        .appApi({
          method: "POST",
          url: url,
          data: formData,
          responseType: "blob",
        })
        .then((response) => {
          if (response.data.errors) {
            this.$store.state.loading = false;
            this.$root.getErrorSystemMessage(response.data.message);
          } else {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `report_stock_${this.dateStart}_to_${this.dateEnd}`
            );
            document.body.appendChild(link);
            link.click();
            this.$store.state.loading = false;
          }
        })
        .catch(() => {
          this.$root.getErrorSystemMessage("can't find vin-no");
          this.$store.state.loading = false;
        });
    },
    search() {
      this.validateDate();
      let url = "user/report/stock/search";
      if (this.$store.getters.isExecutive) url = "user-ex/report/stock/search";
      if (!this.$refs.form.validate()) return;
      if (!this.chartBarActive && !this.chartLineActive) {
        this.chartBarActive = true;
        this.$forceUpdate();
      }
      // this.$store.state.loading = true;
      const formData = new FormData();

      formData.append("dateSt", this.dateStart);
      formData.append("dateEnd", this.dateEnd);

      if (this.yardId) formData.append("yardId", this.yardId);
      if (this.companyId) formData.append("userCompanyId", this.companyId);
      if (this.brandId) formData.append("carBrandId", this.brandId);

      formData.append("download", false);
      this.$root
        .appApi({
          method: "POST",
          url: url,
          data: formData,
        })
        .then((resp) => {
          // this.categories = [];
          let colors = [];
          const defaultData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const entity = resp.data.data;
          if (entity) {
            if (entity[0].Yards) {
              this.series = entity[0].Yards.map(() => {
                return { name: "", data: [...defaultData] };
              });
              this.seriesLine = entity[0].Yards.map(() => {
                return { name: "", data: [...defaultData] };
              });
            } else {
              this.series = [{ name: " ", data: [...defaultData] }];
              this.seriesLine = [{ name: " ", data: [...defaultData] }];
            }
            entity.map((item) => {
              const index = this.categories.findIndex(
                (w) => w == helper.getMonthTh(item.Month)
              );
              if (item.Yards) {
                item.Yards.map((yardItem, yardIdx) => {
                  colors[yardIdx] = this.colors[yardIdx];
                  this.series[yardIdx].name = yardItem.YardName;
                  this.series[yardIdx].data[index] = yardItem.QtyIn;

                  this.seriesLine[yardIdx].name = yardItem.YardName;
                  this.seriesLine[yardIdx].data[index] = yardItem.QtyIn;
                });
              } else {
                colors[0] = this.colors[0];
              }
            });
            this.$refs.chart.updateSeries(this.series);
            this.$refs.chart.updateOptions({
              colors: colors,
              xaxis: {
                tickAmount: entity.lenth,
                categories: this.categories,
              },
              yaxis: {
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                tickAmount: entity.lenth,
                // max: 4500,
                min: 0,
              },
            });

            this.$refs.chartLine.updateSeries(this.seriesLine);
            this.$refs.chartLine.updateOptions({
              colors: colors,
              xaxis: {
                tickAmount: entity.lenth,
                categories: this.categories,
              },
              yaxis: {
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                tickAmount: entity.lenth,
                // max: 4500,
                min: 0,
              },
            });
          }

          this.$store.state.loading = false;
        })
        .catch((err) => {
          if (err.message) {
            this.$root.getErrorSystemMessage(err.message);
          } else {
            this.$root.getErrorSystemMessage(err);
          }
          this.$store.state.loading = false;
        });
    },
    searchChart(status) {
      this.validateDate();
      let url = "user/report/stock/search";
      if (this.$store.getters.isExecutive) url = "user-ex/report/stock/search";
      if (!this.$refs.form.validate()) return;
      if (status) {
        this.chartBarActive = !this.chartBarActive;
        this.chartLineActive = false;
      } else {
        this.chartLineActive = !this.chartLineActive;
        this.chartBarActive = false;
      }
      // this.$store.state.loading = true;
      const formData = new FormData();

      formData.append("dateSt", this.dateStart);
      formData.append("dateEnd", this.dateEnd);

      if (this.yardId) formData.append("yardId", this.yardId);
      if (this.companyId) formData.append("userCompanyId", this.companyId);
      if (this.brandId) formData.append("carBrandId", this.brandId);

      formData.append("download", false);
      this.$root
        .appApi({
          method: "POST",
          url: url,
          data: formData,
        })
        .then((resp) => {
          // this.categories = [];
          let colors = [];
          const defaultData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const entity = resp.data.data;
          if (entity) {
            if (entity[0].Yards) {
              this.series = entity[0].Yards.map(() => {
                return { name: "", data: [...defaultData] };
              });
              this.seriesLine = entity[0].Yards.map(() => {
                return { name: "", data: [...defaultData] };
              });
            } else {
              this.series = [{ name: " ", data: [...defaultData] }];
              this.seriesLine = [{ name: " ", data: [...defaultData] }];
            }
            entity.map((item) => {
              const index = this.categories.findIndex(
                (w) => w == helper.getMonthTh(item.Month)
              );
              if (item.Yards) {
                item.Yards.map((yardItem, yardIdx) => {
                  colors[yardIdx] = this.colors[yardIdx];
                  this.series[yardIdx].name = yardItem.YardName;
                  this.series[yardIdx].data[index] = yardItem.QtyIn;

                  this.seriesLine[yardIdx].name = yardItem.YardName;
                  this.seriesLine[yardIdx].data[index] = yardItem.QtyIn;
                });
              } else {
                colors[0] = this.colors[0];
              }
            });

            this.$refs.chart.updateSeries(this.series);
            this.$refs.chart.updateOptions({
              colors: colors,
              xaxis: {
                tickAmount: entity.lenth,
                categories: this.categories,
              },
              yaxis: {
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                tickAmount: entity.lenth,
                // max: 4500,
                min: 0,
              },
            });

            this.$refs.chartLine.updateSeries(this.seriesLine);
            this.$refs.chartLine.updateOptions({
              colors: colors,
              xaxis: {
                tickAmount: entity.lenth,
                categories: this.categories,
              },
              yaxis: {
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                tickAmount: entity.lenth,
                // max: 4500,
                min: 0,
              },
            });
          }

          this.$store.state.loading = false;
        })
        .catch((err) => {
          if (err.message) {
            this.$root.getErrorSystemMessage(err.message);
          } else {
            this.$root.getErrorSystemMessage(err);
          }
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
