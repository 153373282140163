<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12" sm="1" class="text-center">
        <b>เงื่อนไข</b>
      </v-col>
      <v-col cols="12" sm="11" class="pr-15">
        <v-row>
          <v-col cols="12" sm="8">
            <v-row align="center">
              <v-col cols="12" sm="2" class="pr-8"><b>ช่วงเวลา</b></v-col>
              <v-col cols="12" sm="4">
                <v-menu
                  ref="menuStart"
                  v-model="menuStart"
                  :close-on-content-click="false"
                  :return-value.sync="dateStart"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="pa-0 ma-0 mt-8"
                      v-model="dateStart"
                      label="เดือน/ปีเริ่มต้น"
                      append-icon="mdi-calendar"
                      solo
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateStart"
                    type="month"
                    no-title
                    scrollable
                    locale="th"
                    :max="new Date().toISOString().substr(0, 10)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuStart = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuStart.save(dateStartTh)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              -
              <v-col cols="12" sm="4">
                <v-menu
                  ref="menuEnd"
                  v-model="menuEnd"
                  :close-on-content-click="false"
                  :return-value.sync="dateEnd"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="pa-0 ma-0 mt-8"
                      v-model="dateEnd"
                      label="เดือน/ปีสิ้นสุด"
                      append-icon="mdi-calendar"
                      solo
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateEnd"
                    type="month"
                    no-title
                    scrollable
                    locale="th"
                    :max="new Date().toISOString().substr(0, 10)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuEnd = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuEnd.save(dateEndTh)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            sm="4"
            class="text-right d-flex align-center flex-row"
          >
            <v-row>
              <v-col cols="12" sm="6"> <b></b></v-col>
              <v-col cols="12" sm="6">
                <v-btn
                  class="py-6 px-10"
                  block
                  rounded
                  color="app-theme"
                  dark
                  style="text-transform: none !important"
                  @click="
                    chartBarActive = true;
                    chartLineActive = false;
                  "
                >
                  <b> ค้นหา</b>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" sm="1" class="text-center">
        <b></b>
      </v-col>
      <v-col cols="12" sm="11" class="pr-15">
        <v-row>
          <v-col cols="12" sm="8">
            <v-row align="center">
              <v-col cols="12" sm="2"
                ><b>เลือก Yard</b><req v-if="!$store.getters.isExecutive"></req
              ></v-col>
              <v-col cols="12" sm="4">
                <v-select
                  hide-details="auto"
                  color="app-theme"
                  placeholder="เลือก"
                  v-model="yardId"
                  :items="ddlYard"
                  solo
                >
                </v-select>
              </v-col>
              <b v-if="$store.getters.isExecutive">เลือกลูกค้า</b>
              <b v-if="!$store.getters.isExecutive">เลือกยี่ห้อรถ</b>
              <v-col cols="12" sm="4" v-if="$store.getters.isExecutive">
                <v-select
                  hide-details="auto"
                  color="app-theme"
                  placeholder="เลือกลูกค้า"
                  v-model="customerCompanyId"
                  :items="ddlCustomerCompany"
                  solo
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="4" v-if="!$store.getters.isExecutive">
                <v-select
                  hide-details="auto"
                  color="app-theme"
                  placeholder="เลือกยี่ห้อรถ"
                  v-model="brandId"
                  :items="ddlBrand"
                  solo
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            sm="4"
            class="text-right d-flex align-center flex-row"
          >
            <v-row>
              <v-col cols="12" sm="12">
                <v-btn
                  class="py-6 mr-2"
                  :color="chartBarActive ? 'app-theme' : 'white'"
                  style="text-transform: none !important"
                  @click="
                    chartBarActive = !chartBarActive;
                    chartLineActive = false;
                  "
                >
                  <v-icon
                    :color="chartBarActive ? 'white' : 'app-theme'"
                    x-large
                    >mdi-chart-bar
                  </v-icon>
                </v-btn>
                <v-btn
                  class="py-6"
                  :color="chartLineActive ? 'app-theme' : 'white'"
                  style="text-transform: none !important"
                  @click="
                    chartLineActive = !chartLineActive;
                    chartBarActive = false;
                  "
                >
                  <v-icon
                    :color="chartLineActive ? 'white' : 'app-theme'"
                    x-large
                    >mdi-chart-line
                  </v-icon>
                </v-btn></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" v-if="$store.getters.isExecutive">
      <v-col cols="12" sm="1" class="text-center">
        <b></b>
      </v-col>
      <v-col cols="12" sm="11" class="pr-15">
        <v-row>
          <v-col cols="12" sm="8">
            <v-row align="center">
              <v-col cols="12" sm="2"><b>เลือกยี่ห้อรถ</b></v-col>
              <v-col cols="12" sm="4">
                <v-select
                  hide-details="auto"
                  color="app-theme"
                  placeholder="เลือกลูกค้า"
                  v-model="customerCompanyId"
                  :items="ddlCustomerCompany"
                  solo
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="chartBarActive">
      <v-col cols="12">
        <v-card class="ma-6">
          <v-row class="my-1">
            <v-col cols="12" sm="12" class="pr-15">
              <v-row>
                <v-col cols="12" sm="8">
                  <v-row>
                    <v-col cols="12" sm="12" class="ml-10 mt-10"
                      ><h2 style="color: #203486">
                        {{ chartName }}
                      </h2></v-col
                    >
                  </v-row>
                </v-col>

                <v-col cols="12" sm="4" class="text-right">
                  <v-row>
                    <v-col cols="12" sm="6"> <b></b></v-col>
                    <v-col cols="12" sm="6"> </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0" justify="end">
            <v-col
              cols="12"
              sm="4"
              class="mr-4 pa-0 d-flex align-end flex-column"
            >
              <v-btn
                justify="end"
                class="py-6"
                rounded
                color="#f3b420"
                dark
                style="text-transform: none !important"
                href="mock-file.xlsx"
                download
                target="_blank"
              >
                <b
                  ><v-icon md>mdi-tray-arrow-down</v-icon> ดาวน์โหลดเป็น
                  Excel</b
                >
              </v-btn>
            </v-col>
          </v-row>
          <div id="chart">
            <apexchart
              height="600px"
              type="bar"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="chartLineActive">
      <v-col cols="12">
        <v-card class="ma-6">
          <v-row class="my-1">
            <v-col cols="12" sm="12" class="pr-15">
              <v-row>
                <v-col cols="12" sm="8">
                  <v-row>
                    <v-col cols="12" sm="12" class="ml-10 mt-10"
                      ><h2 style="color: #203486">
                        {{ chartName }}
                      </h2></v-col
                    >
                  </v-row>
                </v-col>

                <v-col cols="12" sm="4" class="text-right">
                  <v-row>
                    <v-col cols="12" sm="6"> <b></b></v-col>
                    <v-col cols="12" sm="6"> </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0" justify="end">
            <v-col
              cols="12"
              sm="4"
              class="mr-4 pa-0 d-flex align-end flex-column"
            >
              <v-btn
                justify="end"
                class="py-6"
                rounded
                color="#f3b420"
                dark
                style="text-transform: none !important"
                href="mock-file.xlsx"
                download
                target="_blank"
              >
                <b
                  ><v-icon md>mdi-tray-arrow-down</v-icon> ดาวน์โหลดเป็น
                  Excel</b
                >
              </v-btn>
            </v-col>
          </v-row>
          <div id="chart">
            <apexchart
              height="600px"
              type="line"
              :options="chartOptionsLine"
              :series="seriesLine"
            ></apexchart>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  watch: {
    dateEnd() {
      const month = new Date(this.dateEnd).getMonth();
      const year = new Date(this.dateEnd).getFullYear();
      this.dateEndTh = `${this.monthTh[month]} ${year}`;
    },
    dateStart() {
      const month = new Date(this.dateStart).getMonth();
      const year = new Date(this.dateStart).getFullYear();
      this.dateStartTh = `${this.monthTh[month]} ${year}`;
    },
  },
  mounted() {
    this.$store.state.title = "Dealer Return,Rematching,Parking Report";
  },
  data: function () {
    return {
      chartName: "Dealer Return,Rematching,Parking Report",
      chartBarActive: false,
      chartLineActive: false,
      monthTh: [
        "ม.ค.",
        "ก.พ.",
        "ม.ค.",
        "ม.ย.",
        "พ.ค.",
        "ม.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      yardId: null,
      ddlYard: [
        { text: "OTTO Land", value: 1 },
        { text: "VLand", value: 2 },
      ],
      brandId: 1,
      ddlBrand: [
        { text: "ทั้งหมด", value: 1 },
        { text: "Mazda", value: 2 },
        { text: "Ford", value: 3 },
      ],
      customerCompanyId: 1,
      ddlCustomerCompany: [
        { text: "ทั้งหมด", value: 1 },
        { text: "MST", value: 2 },
        { text: "AAT", value: 3 },
      ],
      dateStart: null,
      dateStartTh: null,
      menuStart: false,
      dateEnd: null,
      dateEndTh: null,
      menuEnd: false,
      seriesLine: [
        {
          name: "Dealer Return",
          data: [
            0, 2495, 3800, 2400, 2250, 2480, 3600, 2333, 3800, 3700, 2000, 2400,
            3111,
          ],
        },
        {
          name: "Rematching",
          data: [
            0, 2850, 3150, 2800, 2444, 2777, 3433, 2222, 3122, 3444, 2222, 2711,
            4200,
          ],
        },
        {
          name: "Parking",
          data: [
            0, 2600, 3300, 2700, 1600, 3200, 2969, 1800, 2777, 2100, 2800, 2267,
            2777,
          ],
        },
      ],
      series: [
        {
          name: "Dealer Return",
          data: [
            {
              x: "2021-01-01",
              y: 2495,
            },
            {
              x: "2021-02-01",
              y: 3350,
            },
            {
              x: "2021-03-01",
              y: 2700,
            },
            {
              x: "2021-04-01",
              y: 2250,
            },
            {
              x: "2021-05-01",
              y: 3660,
            },
            {
              x: "2021-06-01",
              y: 3550,
            },
            {
              x: "2021-07-01",
              y: 2480,
            },
            {
              x: "2021-08-01",
              y: 2970,
            },
            {
              x: "2021-09-01",
              y: 2200,
            },
            {
              x: "2021-10-01",
              y: 2400,
            },
            {
              x: "2021-11-01",
              y: 3250,
            },
            {
              x: "2021-12-01",
              y: 3700,
            },
          ],
        },
        {
          name: "Rematching",
          data: [
            {
              x: "2021-01-01",
              y: 2800,
            },
            {
              x: "2021-02-01",
              y: 3150,
            },
            {
              x: "2021-03-01",
              y: 2400,
            },
            {
              x: "2021-04-01",
              y: 2660,
            },
            {
              x: "2021-05-01",
              y: 3900,
            },
            {
              x: "2021-06-01",
              y: 3460,
            },
            {
              x: "2021-07-01",
              y: 2800,
            },
            {
              x: "2021-08-01",
              y: 2430,
            },
            {
              x: "2021-09-01",
              y: 2200,
            },
            {
              x: "2021-10-01",
              y: 3400,
            },
            {
              x: "2021-11-01",
              y: 3300,
            },
            {
              x: "2021-12-01",
              y: 3250,
            },
          ],
        },
        {
          name: "Parking",
          data: [
            {
              x: "2021-01-01",
              y: 2600,
            },
            {
              x: "2021-02-01",
              y: 3300,
            },
            {
              x: "2021-03-01",
              y: 2700,
            },
            {
              x: "2021-04-01",
              y: 1600,
            },
            {
              x: "2021-05-01",
              y: 3200,
            },
            {
              x: "2021-06-01",
              y: 2969,
            },
            {
              x: "2021-07-01",
              y: 1800,
            },
            {
              x: "2021-08-01",
              y: 2777,
            },
            {
              x: "2021-09-01",
              y: 2100,
            },
            {
              x: "2021-10-01",
              y: 2800,
            },
            {
              x: "2021-11-01",
              y: 2267,
            },
            {
              x: "2021-12-01",
              y: 2777,
            },
          ],
        },
      ],
      chartOptions: {
        colors: ["#8daefa", "#e483b0", "#b3ebe1"],
        chart: {
          toolbar: { show: false },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 4,
          colors: ["transparent"],
        },
        legend: {
          fontSize: "28px",
          itemMargin: {
            horizontal: 10,
            vertical: 20,
          },
          position: "top",
          horizontalAlign: "right",
          showForSingleSeries: true,
          markers: {
            radius: 25,
            width: 18,
            height: 18,
            strokeWidth: 0,
          },
        },
        yaxis: {
          tickAmount: 9,
          max: 4500,
          min: 0,
        },
        xaxis: {
          categories: [
            "มกราคม",
            "กุมภาพันธ์",
            "มีนาคม",
            "เมษายน",
            "พฤษภาคม",
            "มิถุนายน",
            "กรกฎาคม",
            "สิงหาคม",
            "กันยายน",
            "ตุลาคม",
            "พฤศจิกายน",
            "ธันวาคม",
          ],
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          shared: true,
          intersect: false,
          enabledOnSeries: true,
          x: {
            show: false,
          },
        },
      },
      chartOptionsLine: {
        colors: ["#8daefa", "#e483b0", "#b3ebe1"],
        chart: {
          toolbar: { show: false },
        },
        markers: {
          size: 3,
          strokeOpacity: 1,
          strokeWidth: 0,
          hover: {
            size: undefined,
            sizeOffset: 2,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          curve: "straight",
        },
        legend: {
          fontSize: "28px",
          itemMargin: {
            horizontal: 10,
            vertical: 20,
          },
          position: "top",
          horizontalAlign: "right",
          showForSingleSeries: true,
          markers: {
            radius: 25,
            width: 18,
            height: 18,
            strokeWidth: 0,
          },
        },
        yaxis: {
          tickAmount: 9,
          max: 4500,
          min: 0,
        },
        xaxis: {
          categories: [
            "",
            "มกราคม",
            "กุมภาพันธ์",
            "มีนาคม",
            "เมษายน",
            "พฤษภาคม",
            "มิถุนายน",
            "กรกฎาคม",
            "สิงหาคม",
            "กันยายน",
            "ตุลาคม",
            "พฤศจิกายน",
            "ธันวาคม",
          ],
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          x: {
            show: false,
          },
        },
      },
    };
  },
};
</script>

<style></style>
