export default {
  newDateFormatted() {
    return this.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    );
  },
  formatDate(date) {
    if (!date) return null;
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  },
  formatShortDateTh(date) {
    if (!date) return null;
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${(parseInt(year) + 543).toString().substr(-2)}`;
  },
  parseDate(date) {
    if (!date) return null;
    const [month, day, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  },
  parseDateYMD(date) {
    if (!date) return null;
    const [day, month, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  },
  parseDateYMDFullY(date) {
    if (!date) return null;
    const [day, month, year] = date.split("/");
    return `${2000 + parseInt(year)}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;
  },

  getMonthName(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (typeof date == "string") {
      const [year, month, d] = date.split("-");
      year;
      d;
      return monthNames[parseInt(month) - 1];
    } else {
      return monthNames[date.getMonth()];
    }
  },
  getMonth(value) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[value];
  },
  getMonthTh(name) {
    const en = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const th = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];
    const index = en.findIndex((w) => w == name);
    if (index === -1) return;
    return th[index];
  },
  isNullOrEmpty(str) {
    let flag = false;
    if (str === "" || str === null) {
      return true;
    } else {
      return flag;
    }
  },
};
