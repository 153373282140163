import Vue from "vue";
import Vuex from "vuex";
import axios from "../axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    loading: false,
    dialog: {},
    userId: localStorage.getItem("user"),
    isExecutive: JSON.parse(localStorage.getItem("executive")),
    title: "จัดการผู้ดูแลระบบ",
    isBack: false,
    isBackToName: false,
    fullName: localStorage.getItem("fullName"),
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { token }) {
      state.status = "success";
      state.token = token;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    dialog(state, { message, type }) {
      state.dialog = { message, type, state: true };
    },
  },
  actions: {
    login({ commit }, loginData) {
      return new Promise((resolve, reject) => {
        axios({
          url: "user/login",
          method: "POST",
          data: loginData,
        })
          .then((resp) => {
            const token = resp.data.data.token;
            localStorage.setItem("token", token);
            localStorage.setItem(
              "fullName",
              `${resp.data.data.firstname} ${resp.data.data.lastname}`
            );
            this.state.fullName = `${resp.data.data.firstname} ${resp.data.data.lastname}`;
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            commit("auth_success", { token });
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");

            if (err.response.data.errors) {
              reject(err.response.data.errors);
            } else if (err.message === "Network Error") {
              Promise.reject(new Error("กรุณาตรวจสอบการเชื่อมต่ออินเตอร์เนต"));
            } else {
              Promise.reject(new Error(err.message));
            }
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        // axios({
        //   url: "admin/auth/logout",
        // });
        commit("logout");

        localStorage.removeItem("token");
        localStorage.removeItem("executive");

        this.state.isExecutive = false;

        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    userId: (state) => state.userId,
    title: (state) => state.title,
    isBack: (state) => state.isBack,
    isBackToName: (state) => state.isBackToName,

    isExecutive: (state) => state.isExecutive,
  },
  modules: {},
});
