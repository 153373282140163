<template>
  <v-app id="app">
    <v-app-bar
      prominent
      app
      clipped-left
      class=""
      justify="center"
      v-if="$store.getters.isLoggedIn"
      color="white"
    >
      <v-container fluid>
        <v-row align="center">
          <v-col cols="12" sm="2">
            <v-app-bar-nav-icon
              v-if="$vuetify.breakpoint.xsOnly"
              @click.stop="drawer = !drawer" />

            <v-img
              src="@/assets/ic_logo_menu.svg"
              contain
              max-height="110"
              max-width="250"
            ></v-img
          ></v-col>
          <v-col cols="12" sm="7" class="text-start">
            <div class="ml-14">
              <v-toolbar-title>
                <span style="color: #525252">
                  {{ $store.getters.title }}
                </span></v-toolbar-title
              >
            </div></v-col
          >
          <v-col cols="12" sm="3" class="text-end">
            <div>
              <span class="subtitle-1 mx-4">{{ $store.state.fullName }}</span>
            </div></v-col
          >
        </v-row>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      width="20%"
      dark
      color="app-theme"
      v-if="$store.getters.isLoggedIn"
      v-model="drawer"
      clipped
      hide-overlay
      app
      :permanent="!$vuetify.breakpoint.xsOnly"
    >
      <v-list dark>
        <template v-for="(item, idx) in menus">
          <v-list-group
            v-if="item.items"
            :key="item.title"
            :group="item.group"
            active-class="white--text app-active-nav-bar"
            :class="idx === 0 ? 'r-mt-8' : ''"
          >
            <template v-slot:appendIcon>
              <v-icon x-large color="red">mdi-chevron-right</v-icon>
            </template>
            <template v-slot:activator>
              <v-list-item-icon class="mx-0 mr-4" v-if="item.isIcon">
                <v-icon color="white" class="nav-icon-w-sm">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="nav-md-font"
                  v-text="item.title"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="{ name: child.route || 'Abort' }"
              active-class="white--text"
              :disabled="!child.route"
              link
            >
              <v-list-item-content class="ml-14">
                <v-list-item-title class="nav-md-font" v-if="child.badge">
                  <v-badge
                    tile
                    color="red"
                    inline
                    class="mt-0"
                    :content="child.badge"
                  >
                    {{ child.title }}
                  </v-badge>
                </v-list-item-title>
                <v-list-item-title
                  class="nav-md-font"
                  v-else
                  v-text="child.title"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            link
            :key="item.title"
            :to="{ name: item.route || 'Abort' }"
            active-class="white--text"
            :disabled="!item.route"
            dark
          >
            <v-list-item-icon class="mx-0 mr-4" v-if="item.isIcon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <v-list-item @click="dlgLogout = true">
          <v-list-item-icon class="mr-4">
            <v-img src="@/assets/ic_logout.svg" contain width="16"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>ออกจากระบบ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <v-overlay :value="$store.state.loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog :value="$store.state.dialog.state" persistent max-width="500">
      <v-card class="py-10">
        <v-card-text class="text-center text-body-1">
          <v-icon
            v-if="$store.state.dialog.type == 'error'"
            color="#BDBDBD"
            size="64"
            >mdi-alert-circle</v-icon
          >
          <v-icon
            v-if="$store.state.dialog.type == 'success'"
            color="#20AB2C"
            size="64"
          >
            mdi-check-circle
          </v-icon>
          <div
            class="mt-3"
            v-html="$store.state.dialog.message"
            style="font-weight: bold; color: black"
          ></div
        ></v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="app-theme"
            dark
            class="px-10 py-6"
            @click="$store.state.dialog.state = false"
            >ตกลง</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgLogout" persistent max-width="600">
      <v-card class="py-10">
        <v-card-text class="text-center text-body-1"> </v-card-text>
        <v-card-text class="text-center text-body-1">
          <div class="mt-1">
            <span class="font--delete">ยืนยันการออกจากระบบ</span>
          </div></v-card-text
        >

        <v-card-actions class="d-flex justify-center">
          <v-btn
            dark
            rounded
            color="app-disabled-button-theme"
            class="px-15 py-6 mr-4 btn--action"
            @click="dlgLogout = false"
            >ยกเลิก</v-btn
          >
          <v-btn
            color="app-theme"
            dark
            rounded
            class="px-15 py-6 btn--action"
            @click="logout()"
            >ตกลง</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<style>
@import "assets/fonts/stylesheet.css";
@import "assets/app.css";
.v-list-item__icon .mdi:before,
.mdi-set {
  color: #ffffff !important;
  font-size: 20px !important;
}
.nav-md-font {
  font-size: 20px !important;
}

.v-list-group.v-list-group--active.primary--text
  > div.v-list-group__header.v-list-item.v-list-item--active.v-list-item--link.theme--dark.white--text.app-active-nav-bar
  > div.v-list-item__icon.v-list-group__header__append-icon
  > i {
  transform: rotate(90deg);
}
</style>
<script>
export default {
  name: "App",

  components: {},
  mounted() {
    this.$store.state.title = "";
  },
  data: () => ({
    dlgLogout: false,
    drawer: null,
    menus: [
      {
        title: "จัดการโปรไฟล์",
        group: "profile|change_password",
        items: [
          { title: "โปรไฟล์ส่วนตัว", route: "User.Profile" },
          {
            title: "เปลี่ยนรหัสผ่าน",
            route: "User.ChangePassword",
          },
        ],
        isIcon: true,
        icon: "mdi-account",
      },
      {
        title: "Dashboard",
        group: "inventory|dealer_return_rematching_parking_report|stock|car",
        items: [
          {
            title: "Inventory Capacity Report",
            route: "Inventory.Index",
          },
          // {
          //   title: "Dealer Return, Rematching, Parking Report",
          //   route: "DRP.Index",
          // },
          {
            title: "Stock Report",
            route: "Stock.Index",
          },
          {
            title: "Car Detail",
            route: "Car.Index",
          },
        ],

        isIcon: true,
        icon: "mdi-equalizer",
      },
    ],
  }),
  methods: {
    logout() {
      this.dlgLogout = false;
      this.$store.dispatch("logout").then(() => {
        location.reload();
      });
    },
    backTo(routeName) {
      this.$router.push(`${routeName}`);
    },
  },
};
</script>
