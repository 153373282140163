var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-10"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.update()}}},[_c('v-row',{staticClass:"center"},[_c('v-col',{staticClass:"pl-10",attrs:{"cols":"12","sm":"2"}}),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_vm._v("User ID "),_c('v-text-field',{staticClass:"disabled-text",attrs:{"hide-details":"false","disabled":true,"required":"","background-color":"app-disabled-theme","height":"48","dense":""},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})],1)],1),_c('v-row',{staticClass:"my-5"},[_c('v-col',{staticClass:"pl-10",attrs:{"cols":"12","sm":"2"}}),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-row',{staticClass:"mb-5"},[_c('v-col',[_vm._v(" ชื่อ "),_c('v-text-field',{staticClass:"pl--input",attrs:{"color":"app-theme","placeholder":"กรุณาระบุชื่อ","outlined":"","hide-details":"auto","rules":[
                function (v) { return !!v || 'กรุณาระบุชื่อ'; },
                function (v) { return /^[A-Za-zก-๏]+$/.test(v.trim()) ||
                  'ใส่ได้เฉพาะอักษรภาษาไทยและภาษาอังกฤษ เท่านั้น'; } ]},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}})],1),_c('v-col',[_vm._v("นามสกุล"),_c('v-text-field',{staticClass:"pl--input",attrs:{"color":"app-theme","placeholder":"กรุณาระบุนามสกุล","outlined":"","hide-details":"auto","rules":[
                function (v) { return !!v || 'กรุณาระบุนามสกุล'; },
                function (v) { return /^[A-Za-zก-๏]+$/.test(v.trim()) ||
                  'ใส่ได้เฉพาะอักษรภาษาไทยและภาษาอังกฤษ เท่านั้น'; } ]},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}})],1)],1),_c('v-row',[_c('v-col',[_vm._v(" อีเมล"),_c('v-text-field',{staticClass:"pl--input",attrs:{"color":"app-theme","placeholder":"อีเมล","outlined":"","hide-details":"auto","rules":[function (v) { return /.+@.+/.test(v) || 'กรุณาระบุอีเมล'; }]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',[_vm._v("เบอร์โทรศัพท์ "),_c('v-text-field',{staticClass:"pl--input",attrs:{"color":"app-theme","placeholder":"เบอร์โทรศัพท์","outlined":"","hide-details":"auto","rules":[
                function (v) { return v.length == 12 || 'กรุณาระบุเบอร์โทรศัพท์ให้ครบทั้ง 10 ตัว'; } ]},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}})],1)],1)],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pl-10",attrs:{"cols":"12","sm":"2"}}),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_vm._v("ชื่อผู้ใช้ "),_c('v-text-field',{staticClass:"disabled-text",attrs:{"hide-details":"false","disabled":true,"required":"","background-color":"app-disabled-theme","height":"48","dense":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1),_c('v-row',{staticClass:"my-5",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"mx-auto d-flex justify-center",attrs:{"cols":"12","sm":"8"}},[_c('v-btn',{staticClass:"mr-4 my-2 px-8 py-6 b-r-10",attrs:{"color":"app-disabled-button-theme","dark":""},on:{"click":function($event){return _vm.get()}}},[_vm._v("ยกเลิก")]),_c('v-btn',{staticClass:"my-2 px-8 py-6 b-r-10",attrs:{"color":"app-theme","dark":"","type":"submit"}},[_vm._v("บันทึก")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }