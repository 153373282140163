import Vue from "vue";
import App from "./App.vue";
import router from "./routers";
import store from "./store";
import vuetify from "./plugins/vuetify";
import AxiosLib from "axios";
import axios from "./axios";
import helperMessage from "./helpers/message";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
Vue.config.productionTip = false;

Vue.config.productionTip = false;
Vue.config.ignoredElements = ["req"];

new Vue({
  router,
  store,
  vuetify,
  helperMessage,
  render: (h) => h(App),
  el: "#app",
  created() {
    if (
      !store.getters.isLoggedIn &&
      router.currentRoute.name != "Login" &&
      router.currentRoute.name != "ResetPassword"
    ) {
      router.push("/login");
    }
  },
  methods: {
    appApi: (options) => {
      return new Promise((resolve, reject) => {
        axios(
          Object.assign(
            {
              method: "GET",
              timeout: 0,
              processData: false,
            },
            options
          )
        )
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.data.errors) {
                if (err.response.data.errors.message) {
                  reject(err.response.data.errors.message);
                } else {
                  reject(err.response.data);
                }
              } else if (err.response.statusText == "Unauthorized") {
                if (localStorage.getItem("token") == this.$store.state.token) {
                  this.$store.dispatch("logout").then(() => {
                    this.$router.push("/login");
                  });
                } else {
                  location.reload();
                }
              } else {
                reject(err.response.status + " " + err.response.statusText);
              }
            } else if (err.message == "Network Error") {
              reject("กรุณาตรวจสอบการเชื่อมต่ออินเตอร์เนต");
            } else {
              reject(err.message);
            }
          });
      });
    },
    getSystemMessage(error) {
      const message = helperMessage.getSystemMessage(error);
      store.commit("dialog", {
        message: message,
        type: "success",
      });
      store.state.loading = false;
    },
    getErrorSystemMessage(error) {
      const message = helperMessage.getErrorMessage(error);
      store.commit("dialog", {
        message: message,
        type: "error",
      });
      store.state.loading = false;
    },
  },
}).$mount("#app");

Vue.prototype.$http = AxiosLib;
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${token}`;
}
