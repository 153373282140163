<template>
  <div>
    <v-form @submit.prevent="search" ref="form">
      <v-row class="my-1" align="center">
        <v-col cols="12" sm="2" class="text-center">
          <b>VIN No.</b>
        </v-col>
        <v-col cols="12" sm="5">
          <v-row align="center">
            <v-col cols="12" sm="8">
              <v-text-field
                hide-details="auto"
                color="app-theme"
                placeholder=""
                v-model="keywordVinNo"
                solo
                :rules="[(v) => !!v || 'กรุณาระบุ VIN No.']"
              >
              </v-text-field
            ></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="5">
          <v-row justify="end" class="pr-10">
            <v-btn
              class="py-6 px-10 mr-2"
              rounded
              color="app-theme"
              dark
              style="text-transform: none !important"
              type="submit"
            >
              <b> ค้นหา</b>
            </v-btn>
            <!-- <v-btn
              class="py-6"
              rounded
              color="#f3b420"
              dark
              style="text-transform: none !important"
              @click="downloadPDF()"
            >
              <b><v-icon md>mdi-tray-arrow-down</v-icon> Download PDF</b>
            </v-btn> -->
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="mb-10" v-if="showDetail">
      <v-col cols="12">
        <v-card class="ma-4 mb-15">
          <v-row class="ml-6 pt-10">
            <v-col cols="12" sm="12"
              ><b class="text--color--theme">Card Detail</b></v-col
            >
          </v-row>
          <v-row class="ml-9 pl-7 mb-2">
            <v-col cols="12" sm="3">
              <v-row>
                <b class="text--color--theme" style="width: 190px"> Brand : </b>
                <div class="text--color--theme">
                  {{ brand }}
                </div>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" class="pl-8">
              <v-row>
                <b class="text--color--theme mr-4">Vin No. :</b>
                <div>
                  <b>{{ vinNo }}</b>
                </div>
              </v-row>
            </v-col>
            <v-col cols="12" sm="5">
              <v-row>
                <b class="text--color--theme" style="width: 150px">
                  RFID Tag No. :
                </b>
                <div class="text--color--theme">{{ rfidNo }}</div>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="ml-9 pl-7 mb-2">
            <v-col cols="12" sm="7">
              <v-row>
                <b class="text--color--theme" style="width: 190px">
                  Model Code:
                </b>
                <div class="text--color--theme" style="width: 80px">
                  {{ modelCode }}
                </div>
              </v-row>
            </v-col>
            <v-col cols="12" sm="5">
              <v-row>
                <b class="text--color--theme" style="width: 150px">
                  Status :
                </b>
                <div class="text--color--theme">{{ status }}</div>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ml-9 pl-7 mb-2">
            <b class="text--color--theme" style="width: 190px"> MSC CODE: </b>
            <div class="text--color--theme">{{ mscCode }}</div>
          </v-row>
          <v-row class="ml-9 pl-7 mb-2">
            <b class="text--color--theme" style="width: 190px">
              MSC Description:
            </b>
            <div class="text--color--theme">{{ mscDesc }}</div>
          </v-row>
          <v-row class="pa-0 my-2 ml-4">
            <v-col cols="12" sm="6">
              <v-divider style="background-color: black"></v-divider
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" class="ml-6"
              ><b class="text--color--theme">Current Location</b></v-col
            >
          </v-row>
          <v-row class="ml-9 pl-7 mb-2">
            <v-col md="3" sm="4">
              <v-row>
                <b
                  class="text--color--theme"
                  style="width: 190px; min-width: 115px"
                >
                  Yard:
                </b>
                <div class="text--color--theme" style="width: 120px">
                  {{ yard }}
                </div>
              </v-row>
            </v-col>
            <v-col md="4" sm="4" class="pl-8">
              <v-row>
                <b class="text--color--theme" style="width: 100px"> Phase: </b>
                <div class="text--color--theme">
                  {{ phase }}
                </div>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ml-9 pl-7 mb-7">
            <v-col md="3" sm="3">
              <v-row>
                <b class="text--color--theme" style="width: 190px"> Zone: </b>
                <div class="text--color--theme">{{ zone }}</div>
              </v-row>
            </v-col>
            <v-col md="4" sm="4" class="pl-8">
              <v-row>
                <b class="text--color--theme" style="width: 100px">Row:</b>
                <div class="text--color--theme">{{ row }}</div>
              </v-row>
            </v-col>
          </v-row>
          <v-data-table
            hide-default-footer
            :headers="headers"
            :items="items"
            class="elevation-1 header-light-green"
            :page.sync="page"
            :items-per-page="pageSize"
            @page-count="pageCount = $event"
            :header-props="{
              sortIcon: 'mdi-menu-up',
            }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              'items-per-page-options': rowsPerPage,
            }"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <Pagination
      ref="Pagination"
      :page="page"
      :page-size="pageSize"
      @changePage="page = $event"
    />
  </div>
</template>

<style scoped>
.v-card >>> ::-webkit-scrollbar {
  height: 20px !important;
  border: 1px solid #aaa !important;
  border-radius: 15px !important;
}
.v-card >>> ::-webkit-scrollbar-thumb {
  background: #283593 !important;
  border-radius: 15px !important;
}
</style>
<script>
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  mounted() {
    this.$store.state.title = "Car Detail";
  },
  data: function () {
    return {
      showDetail: false,
      page: 1,
      pageCount: 0,
      pageSize: 10,
      rowsPerPage: [5, 10, 15, 30, 50, 100, { text: "All", value: -1 }],
      headers: [
        {
          text: "Date/Time",
          align: "center",
          value: "dateTime",
          width: "300px",
          class: "text-center details",
        },
        {
          text: "Action",
          align: "center",
          value: "action",
          width: "160px",
          class: "text-center details",
        },
        {
          text: "Assign type",
          align: "center",
          value: "assignType",
          width: "160px",
          class: "text-center details",
        },
        {
          text: "Assigner",
          align: "center",
          value: "assigner",
          width: "160px",
          class: "text-center details",
        },
        {
          text: "Assignee",
          align: "center",
          value: "assignee",
          width: "160px",
          class: "text-center details",
        },
        {
          text: "Assign Time",
          align: "center",
          value: "assignTime",
          width: "300px",
          class: "text-center details",
        },
        {
          text: "Driver",
          align: "center",
          value: "driver",
          width: "160px",
          class: "text-center details",
        },
        {
          text: "Gate",
          align: "center",
          value: "gate",
          width: "160px",
          class: "text-center details",
        },
        {
          text: "Gate Time",
          align: "center",
          value: "gate",
          width: "300px",
          class: "text-center details",
        },
        {
          text: "Arrival Time",
          align: "center",
          value: "arrivalAt",
          width: "300px",
          class: "text-center details",
        },

        {
          text: "DealerZone",
          align: "center",
          value: "dealerZone",
          width: "160px",
          class: "text-center details",
        },
        {
          text: "DealerCode",
          align: "center",
          value: "dealerCode",
          width: "160px",
          class: "text-center details",
        },
        {
          text: "DealerName",
          align: "center",
          value: "dealerName",
          width: "300px",
          class: "text-center details",
        },
        {
          text: "DestinationCode",
          align: "center",
          value: "destinationCode",
          width: "400px",
          class: "text-center details",
        },
        {
          text: "TripNo",
          align: "center",
          value: "tripNo",
          width: "160px",
          class: "text-center details",
        },
        {
          text: "Trailer",
          align: "center",
          value: "trailer",
          width: "160px",
          class: "text-center details",
        },
      ],
      items: [],
      keywordVinNo: "",
      model: "",
      vinNo: "",
      rfidNo: "",
      modelCode: "",
      status: "",
      mscCode: "",
      mscDesc: "",
      yard: "",
      phase: "",
      zone: "",
      row: "",
      brand: "",
    };
  },
  methods: {
    parseFullDateTime(value) {
      if (!value) return null;
      const [date, time] = value.split(" ");
      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year} ${time} `;
    },
    downloadPDF() {
      let url = "user/report/car-detail/search";
      if (this.$store.getters.isExecutive)
        url = "user-ex/report/car-detail/search";
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();

      formData.append("vinNo", this.keywordVinNo);

      formData.append("download", true);
      this.$root
        .appApi({
          method: "POST",
          url: url,
          data: formData,
          responseType: "blob",
        })
        .then((response) => {
          if (response.data.errors) {
            this.$store.state.loading = false;
            this.$root.getErrorSystemMessage(response.data.message);
          } else {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: "application/pdf",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `car-detail_${this.keywordVinNo}`);
            document.body.appendChild(link);
            link.click();
            this.$store.state.loading = false;
          }
        })
        .catch(() => {
          this.$root.getErrorSystemMessage("can't find vin-no");
          this.$store.state.loading = false;
        });
    },
    search() {
      let url = "user/report/car-detail/search";
      if (this.$store.getters.isExecutive)
        url = "user-ex/report/car-detail/search";
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();

      formData.append("vinNo", this.keywordVinNo);

      formData.append("download", false);

      this.$root
        .appApi({
          method: "POST",
          url: url,
          data: formData,
        })
        .then((resp) => {
          const entity = resp.data.data;
          if (entity) {
            this.showDetail = true;
            if (entity.Activities) {
              let items = [];
              entity.Activities.map((item) => {
                const fileCar = {
                  dateTime: this.parseFullDateTime(item.FileCarIn.dateAt),
                  action: item.FileCarIn.action,
                  assignType: item.FileCarIn.assignType,
                  assigner: item.FileCarIn.assigner,
                  assignee: item.FileCarIn.assignee,
                  assignTime: this.parseFullDateTime(item.FileCarIn.assignAt),
                  driver: item.FileCarIn.driver,
                  gate: item.FileCarIn.gate,
                  gateTime: item.FileCarIn.gateAt,
                  arrivalAt: this.parseFullDateTime(item.FileCarIn.arrivalAt),
                  dealerZone: item.FileCarIn.dealerZone,
                  dealerCode: item.FileCarIn.dealerCode,
                  dealerName: item.FileCarIn.dealerName,
                  destinationCode: item.FileCarIn.destinationCode,
                  tripNo: item.FileCarIn.tripNo,
                  trailer: item.FileCarIn.trailer,
                };
                const carIn = {
                  dateTime: this.parseFullDateTime(item.CarIn.dateAt),
                  action: "นำรถเข้า",
                  assignType: item.CarIn.assignType,
                  assigner: item.CarIn.assigner,
                  assignee: item.CarIn.assignee,
                  assignTime: this.parseFullDateTime(item.CarIn.assignAt),
                  driver: item.CarIn.driver,
                  gate: item.CarIn.gate,
                  gateTime: item.CarIn.gateAt,
                  arrivalAt: this.parseFullDateTime(item.CarIn.arrivalAt),
                  dealerZone: item.CarIn.dealerZone,
                  dealerCode: item.CarIn.dealerCode,
                  dealerName: item.CarIn.dealerName,
                  destinationCode: item.CarIn.destinationCode,
                  tripNo: item.CarIn.tripNo,
                  trailer: item.CarIn.trailer,
                };
                const carOut = {
                  dateTime: this.parseFullDateTime(item.CarOut.dateAt),
                  action: "นำรถออก",
                  assignType: item.CarOut.assignType,
                  assigner: item.CarOut.assigner,
                  assignee: item.CarOut.assignee,
                  assignTime: this.parseFullDateTime(item.CarOut.assignAt),
                  driver: item.CarOut.driver,
                  gate: item.CarOut.gate,
                  gateTime: item.CarOut.gateAt,
                  arrivalAt: this.parseFullDateTime(item.CarOut.arrivalAt),
                  dealerZone: item.CarOut.dealerZone,
                  dealerCode: item.CarOut.dealerCode,
                  dealerName: item.CarOut.dealerName,
                  destinationCode: item.CarOut.destinationCode,
                  tripNo: item.CarOut.tripNo,
                  trailer: item.CarOut.trailer,
                };
                if (!carOut.dateTime === false) {
                  items.push(carOut);
                }
                items.push(carIn);
                items.push(fileCar);
              });
              this.items = items;
            }
            if (entity.CarDetail) {
              const carDetail = entity.CarDetail;

              if (carDetail.carImporting) {
                this.brand = carDetail.carImporting.carBrand
                  ? carDetail.carImporting.carBrand.name
                  : "";
              }
              this.vinNo = carDetail.vinNo;

              this.rfidNo = carDetail.rfidNo;
              this.modelCode = carDetail.modelCode;
              if (
                carDetail.status.toLowerCase() == "park" ||
                carDetail.status.toLowerCase() == "done"
              ) {
                this.status = "In-Stock";
              } else if (carDetail.status.toLowerCase() == "out") {
                this.status = "Out-Stock";
              } else {
                this.status = "-";
              }
              this.mscCode = carDetail.mscCode;
              this.mscDesc = carDetail.carModelMsc
                ? carDetail.carModelMsc.description
                : "";

              this.yard = carDetail.yardPhase.yard
                ? carDetail.yardPhase.yard.name
                : "-";
              this.phase = carDetail.yardPhase ? carDetail.yardPhase.name : "";
              this.zone = carDetail.yardZone ? carDetail.yardZone.name : "";
              this.row = carDetail.yardZoneRow ? carDetail.yardZoneRow.no : "";
            }
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.showDetail = false;
          if (err.errors == `can't find vin-no`) {
            this.$root.getErrorSystemMessage("ไม่พบ VIN No. ที่ค้นหา");
          } else {
            this.$root.getErrorSystemMessage(err);
          }
          this.$store.state.loading = false;
        });
    },
  },
};
</script>
