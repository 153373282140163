import axios from "axios";

//axios settings
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.defaults.headers.common = {
  Accept: "application/json",
  "X-Requested-With": "XMLHttpRequest",
  Authorization: `Bearer ${localStorage.getItem("token")}` || "",
};

export default axios;
