import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        // "app-primary": "#DF8536",
        "app-theme": "#0B2E8D",
        "app-danger": "#fe000d",
        "app-disabled-theme": "#eeeeee",
        "app-disabled-button-theme": "#9f9fa0",
        "app-active-nav-bar": "#000000",
      },
    },
  },
});
