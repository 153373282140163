<template>
  <v-container class="my-10">
    <v-form @submit.prevent="update()" ref="form">
      <v-row justify="center">
        <v-col cols="12" sm="7">
          กรุณาอย่าเปิดเผยรหัสผ่านแก่คนอื่นๆ
          เพื่อความปลอดภัยของบัญชีผู้ใช้คุณเอง
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="7"
          ><req></req> รหัสผ่านเก่า
          <v-text-field
            background-color="white"
            class="pl--input"
            v-model="oldPassword"
            color="app-theme"
            placeholder="ระบุรหัสผ่านเก่า"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุรหัสผ่าน',
              (v) => (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
              (v) =>
                /[A-Za-z]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
              (v) =>
                /[0-9]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
            ]"
            :type="showPassword ? 'text' : 'password'"
            ><template v-slot:append>
              <v-img
                v-if="showPassword"
                @click="showPassword = false"
                class="pt-2"
                src="@/assets/ic_eye_on.svg"
                contain
                width="26"
              ></v-img>
              <v-img
                v-else
                @click="showPassword = true"
                class="pt-2"
                src="@/assets/ic_eye_off.svg"
                contain
                width="26"
              ></v-img> </template
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="7"
          ><req></req> ระบุรหัสผ่านใหม่
          <v-text-field
            background-color="white"
            class="pl--input"
            v-model="newPassword"
            color="app-theme"
            placeholder="ระบุรหัสผ่านใหม่"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุรหัสผ่าน',
              (v) => (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
              ,
              (v) =>
                v === (confirmNewPassword || '') ||
                'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',
              (v) =>
                /[A-Za-z]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
              (v) =>
                /[0-9]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
            ]"
            :type="showNewPassword ? 'text' : 'password'"
            ><template v-slot:append>
              <v-img
                v-if="showNewPassword"
                @click="showNewPassword = false"
                class="pt-2"
                src="@/assets/ic_eye_on.svg"
                contain
                width="26"
              ></v-img>
              <v-img
                v-else
                @click="showNewPassword = true"
                class="pt-2"
                src="@/assets/ic_eye_off.svg"
                contain
                width="26"
              ></v-img> </template
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="7"
          ><req></req> ยืนยันระบุรหัสผ่านใหม่
          <v-text-field
            background-color="white"
            class="pl--input"
            v-model="confirmNewPassword"
            color="app-theme"
            placeholder="ระบุยืนยันรหัสผ่านใหม่"
            outlined
            hide-details="auto"
            :rules="[
              (v) => !!v || 'กรุณาระบุยืนยันรหัสผ่าน',
              (v) => (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6',
              ,
              (v) =>
                v === (newPassword || '') ||
                'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',
              (v) =>
                /[A-Za-z]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
              (v) =>
                /[0-9]/.test(v) ||
                'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน',
            ]"
            :type="showConfirmNewPassword ? 'text' : 'password'"
            ><template v-slot:append>
              <v-img
                v-if="showConfirmNewPassword"
                @click="showConfirmNewPassword = false"
                class="pt-2"
                src="@/assets/ic_eye_on.svg"
                contain
                width="26"
              ></v-img>
              <v-img
                v-else
                @click="showConfirmNewPassword = true"
                class="pt-2"
                src="@/assets/ic_eye_off.svg"
                contain
                width="26"
              ></v-img> </template
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-5">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            @click="clearInput"
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6 b-r-10"
            >ยกเลิก</v-btn
          >

          <v-btn
            color="app-theme"
            dark
            class="my-2 px-8 py-6 b-r-10"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    showConfirmNewPassword: false,
    showPassword: false,
    showNewPassword: false,
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  }),
  mounted() {
    this.$store.state.title = "เปลี่ยนรหัสผ่าน";
  },
  watch: {
    newPassword() {
      if (!this.$refs.form.validate()) return;
    },
    confirmNewPassword() {
      if (!this.$refs.form.validate()) return;
    },
  },
  methods: {
    clearInput() {
      this.showConfirmNewPassword = false;
      this.showPassword = false;
      this.showNewPassword = false;
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmNewPassword = "";
    },
    update() {
      if (!this.$refs.form.validate()) return null;
      this.$store.state.loading = true;
      const formData = new FormData();

      formData.append("password", this.oldPassword);
      formData.append("newpassword", this.newPassword);
      this.$root
        .appApi({
          method: "PUT",
          url: "user/profile/password",
          data: formData,
        })
        .then(() => {
          this.$root.getSystemMessage("บันทึกรหัสผ่านเรียบร้อยแล้ว");
          this.$store.state.loading = false;
        })
        .catch((err) => {
          if (err.errors == "password is incorrect")
            this.$root.getErrorSystemMessage(
              "เปลี่ยนรหัสผ่านไม่สำเร็จ กรุณาลองอีกครั้ง"
            );
          else this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style></style>
