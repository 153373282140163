<template>
  <v-container class="my-10">
    <v-form @submit.prevent="update()" ref="form">
      <v-row class="center">
        <v-col cols="12" sm="2" class="pl-10"> </v-col>
        <v-col cols="12" sm="8"
          >User ID
          <v-text-field
            class="disabled-text"
            v-model="userId"
            hide-details="false"
            :disabled="true"
            required
            background-color="app-disabled-theme"
            height="48"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="my-5">
        <v-col cols="12" sm="2" class="pl-10"> </v-col>
        <v-col cols="12" sm="8">
          <v-row class="mb-5">
            <v-col>
              ชื่อ
              <v-text-field
                class="pl--input"
                v-model="firstname"
                color="app-theme"
                placeholder="กรุณาระบุชื่อ"
                outlined
                hide-details="auto"
                :rules="[
                  (v) => !!v || 'กรุณาระบุชื่อ',
                  (v) =>
                    /^[A-Za-zก-๏]+$/.test(v.trim()) ||
                    'ใส่ได้เฉพาะอักษรภาษาไทยและภาษาอังกฤษ เท่านั้น',
                ]"
              ></v-text-field
            ></v-col>
            <v-col
              >นามสกุล<v-text-field
                class="pl--input"
                v-model="lastname"
                color="app-theme"
                placeholder="กรุณาระบุนามสกุล"
                outlined
                hide-details="auto"
                :rules="[
                  (v) => !!v || 'กรุณาระบุนามสกุล',
                  (v) =>
                    /^[A-Za-zก-๏]+$/.test(v.trim()) ||
                    'ใส่ได้เฉพาะอักษรภาษาไทยและภาษาอังกฤษ เท่านั้น',
                ]"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col>
              อีเมล<v-text-field
                class="pl--input"
                v-model="email"
                color="app-theme"
                placeholder="อีเมล"
                outlined
                hide-details="auto"
                :rules="[(v) => /.+@.+/.test(v) || 'กรุณาระบุอีเมล']"
              ></v-text-field>
            </v-col>
            <v-col
              >เบอร์โทรศัพท์
              <v-text-field
                class="pl--input"
                v-model="tel"
                color="app-theme"
                placeholder="เบอร์โทรศัพท์"
                outlined
                hide-details="auto"
                :rules="[
                  (v) =>
                    v.length == 12 || 'กรุณาระบุเบอร์โทรศัพท์ให้ครบทั้ง 10 ตัว',
                ]"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="2" class="pl-10"> </v-col>
        <v-col cols="12" sm="8"
          >ชื่อผู้ใช้
          <v-text-field
            class="disabled-text"
            v-model="username"
            hide-details="false"
            :disabled="true"
            required
            background-color="app-disabled-theme"
            height="48"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters class="my-5" align="center">
        <v-col cols="12" sm="8" class="mx-auto d-flex justify-center">
          <v-btn
            color="app-disabled-button-theme"
            dark
            class="mr-4 my-2 px-8 py-6 b-r-10"
            @click="get()"
            >ยกเลิก</v-btn
          >
          <v-btn
            color="app-theme"
            dark
            class="my-2 px-8 py-6 b-r-10"
            type="submit"
            >บันทึก</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    userId: ``,
    firstname: ``,
    lastname: ``,
    email: ``,
    tel: ``,
    username: ``,
  }),
  mounted() {
    this.$store.state.title = "โปรไฟล์ส่วนตัว";
    this.get();
  },
  methods: {
    get() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "user/profile",
        })
        .then((resp) => {
          const entity = resp.data.data;

          this.userId = entity.id.toString().padStart(5, "0");
          this.firstname = entity.firstname;
          this.lastname = entity.lastname;
          this.tel = entity.tel;
          this.email = entity.email;
          this.username = entity.username;

          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
        });
    },
    update() {
      if (!this.$refs.form.validate()) return;
      this.$store.state.loading = true;
      const formData = new FormData();

      formData.append("firstname", this.firstname.trim());
      formData.append("lastname", this.lastname.trim());
      formData.append("email", this.email);
      formData.append("tel", this.tel);

      this.$root
        .appApi({
          method: "PATCH",
          url: "user/profile/",
          data: formData,
        })
        .then((resp) => {
          localStorage.setItem(
            "fullName",
            `${resp.data.data.firstname} ${resp.data.data.lastname}`
          );
          this.$store.state.fullName = `${resp.data.data.firstname} ${resp.data.data.lastname}`;
          this.$root.getSystemMessage(resp.data.message);
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.disabled-text >>> .v-input__slot::before {
  border-style: none !important;
}
.disabled-text >>> input {
  color: black;
  margin-left: 24px;
}
.v-text-field >>> .app-disabled-theme {
  border-radius: 10px !important;
}

.v-text-field--outlined >>> fieldset {
  border: 2px solid #0b2e8d !important;
}
</style>
