<template>
  <v-app class="section-container">
    <v-row>
      <v-col cols="4" class="pa-0 col-image left">
        <v-row>
          <v-col cols="12">
            <div class="white--text login-text">
              <b>เข้าสู่ระบบ</b>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8" class="right">
        <v-form @submit.prevent="submit" ref="form">
          <v-row>
            <v-col cols="12" align="center">
              <v-img
                min-width="100"
                max-width="200"
                src="@/assets/ic_logo_login.svg"
              />
            </v-col>
            <v-col cols="12" class="py-0 my-0" style="color: black">
              ชื่อผู้ใช้</v-col
            >
            <v-col cols="12" class="">
              <v-text-field
                class="pl--input"
                hide-details="auto"
                color="app-theme"
                v-model="email"
                label="ชื่อผู้ใช้"
                outlined
                :rules="userNameRulues"
              ></v-text-field
            ></v-col>
            <v-col cols="12" class="pb-0 mb-0" style="color: black">
              รหัสผ่าน</v-col
            >
            <v-col cols="12" class="pb-6">
              <v-text-field
                class="pl--input"
                hide-details="auto"
                color="app-theme"
                v-model="password"
                label="รหัสผ่าน"
                @click:append="showPass = !showPass"
                outlined
                :type="showPass ? 'text' : 'password'"
                :rules="passwordRules"
              >
                <template v-slot:append>
                  <v-img
                    v-if="showPass"
                    @click="showPass = false"
                    class="pt-2"
                    src="@/assets/ic_eye_on.svg"
                    contain
                    width="26"
                  ></v-img>
                  <v-img
                    v-else
                    @click="showPass = true"
                    class="pt-2"
                    src="@/assets/ic_eye_off.svg"
                    contain
                    width="26"
                  ></v-img>
                </template> </v-text-field
            ></v-col>
          </v-row>

          <div class="text-center mt-8">
            <v-btn block class="py-6" type="submit" color="red" dark>
              <b>เข้าสู่ระบบ</b>
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    email: "",
    password: null,
    showPass: false,
    userNameRulues: [(v) => !!v || "กรุณาระบุชื่อผู้ใช้"],
    passwordRules: [
      (v) => !!v || "กรุณาระบุรหัสผ่าน",
      (v) => (v && v.length >= 6) || "รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6",
    ],
  }),
  mounted() {
    if (localStorage.getItem("token")) this.$router.push("/about");
  },
  methods: {
    submit() {
      this.email = this.email.trim();
      if (!this.$refs.form.validate()) return;

      this.$store.state.loading = true;
      this.$store
        .dispatch("login", {
          username: this.email,
          password: this.password,
        })
        .then(() => {
          this.getProfile();
        })
        .catch((err) => {
          this.$store.state.loading = false;
          if (err === "record not found") {
            err = "ไม่พบผู้ใช้งานนี้ในระบบ";
          }
          this.$root.getErrorSystemMessage(err);
        });

      this.$store.state.loading = false;
    },
    getProfile() {
      this.$store.state.loading = true;
      this.$root
        .appApi({
          url: "user/profile",
        })
        .then((resp) => {
          const entity = resp.data.data;
          if (entity) {
            if (entity.roles[0].name === "Executive") {
              this.$store.state.isExecutive = true;
              localStorage.setItem("executive", true);
            } else {
              this.$store.state.isExecutive = false;
              localStorage.setItem("executive", false);
            }
            localStorage.setItem(
              "fullName",
              `${entity.firstname} ${entity.lastname}`
            );
            this.$store.state.loading = false;
            this.$router.push("/about");
            this.$store.state.title = "";
          }
          this.$store.state.loading = false;
        })
        .catch((err) => {
          this.$root.getErrorSystemMessage(err);
        });
    },
  },
};
</script>

<style scoped>
.section-container {
  padding: 0;
  margin: 0;
  background: #fff;
  width: 100%;
}
.col-image {
  /* position: absolute; */
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: var(--v-app-theme-base);
  background-image: url("~@/assets/ic_photo_login.svg");
}
.left {
  padding-bottom: 7%;
  flex: start;
  align-items: center;
  box-sizing: border-box;
  display: flex;
}
.right {
  padding-bottom: 10%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--v-app-theme-base);
  background-color: white;
}
.login-text {
  margin: 70px;
  border-bottom: 1px solid #000;
  width: 50%;
  font-size: 50px;
  font-weight: bold;
  border-color: white;
}
.v-text-field >>> label {
  color: rgba(0, 0, 0, 0.3);
}
.v-text-field--outlined >>> fieldset {
  border: 2px solid #0b2e8d !important;
}
.icon--color {
  color: rgba(0, 0, 0, 0.3);
}
</style>
