var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-10"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.update()}}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_vm._v(" กรุณาอย่าเปิดเผยรหัสผ่านแก่คนอื่นๆ เพื่อความปลอดภัยของบัญชีผู้ใช้คุณเอง ")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('req'),_vm._v(" รหัสผ่านเก่า "),_c('v-text-field',{staticClass:"pl--input",attrs:{"background-color":"white","color":"app-theme","placeholder":"ระบุรหัสผ่านเก่า","outlined":"","hide-details":"auto","rules":[
            function (v) { return !!v || 'กรุณาระบุรหัสผ่าน'; },
            function (v) { return (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6'; },
            function (v) { return /[A-Za-z]/.test(v) ||
              'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน'; },
            function (v) { return /[0-9]/.test(v) ||
              'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน'; } ],"type":_vm.showPassword ? 'text' : 'password'},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showPassword)?_c('v-img',{staticClass:"pt-2",attrs:{"src":require("@/assets/ic_eye_on.svg"),"contain":"","width":"26"},on:{"click":function($event){_vm.showPassword = false}}}):_c('v-img',{staticClass:"pt-2",attrs:{"src":require("@/assets/ic_eye_off.svg"),"contain":"","width":"26"},on:{"click":function($event){_vm.showPassword = true}}})]},proxy:true}]),model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('req'),_vm._v(" ระบุรหัสผ่านใหม่ "),_c('v-text-field',{staticClass:"pl--input",attrs:{"background-color":"white","color":"app-theme","placeholder":"ระบุรหัสผ่านใหม่","outlined":"","hide-details":"auto","rules":[
            function (v) { return !!v || 'กรุณาระบุรหัสผ่าน'; },
            function (v) { return (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6'; },
            ,
            function (v) { return v === (_vm.confirmNewPassword || '') ||
              'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน'; },
            function (v) { return /[A-Za-z]/.test(v) ||
              'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน'; },
            function (v) { return /[0-9]/.test(v) ||
              'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน'; } ],"type":_vm.showNewPassword ? 'text' : 'password'},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showNewPassword)?_c('v-img',{staticClass:"pt-2",attrs:{"src":require("@/assets/ic_eye_on.svg"),"contain":"","width":"26"},on:{"click":function($event){_vm.showNewPassword = false}}}):_c('v-img',{staticClass:"pt-2",attrs:{"src":require("@/assets/ic_eye_off.svg"),"contain":"","width":"26"},on:{"click":function($event){_vm.showNewPassword = true}}})]},proxy:true}]),model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('req'),_vm._v(" ยืนยันระบุรหัสผ่านใหม่ "),_c('v-text-field',{staticClass:"pl--input",attrs:{"background-color":"white","color":"app-theme","placeholder":"ระบุยืนยันรหัสผ่านใหม่","outlined":"","hide-details":"auto","rules":[
            function (v) { return !!v || 'กรุณาระบุยืนยันรหัสผ่าน'; },
            function (v) { return (v && v.length >= 6) || 'รหัสผ่านขั้นต่ำต้องมีอย่างน้อย 6'; },
            ,
            function (v) { return v === (_vm.newPassword || '') ||
              'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน'; },
            function (v) { return /[A-Za-z]/.test(v) ||
              'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน'; },
            function (v) { return /[0-9]/.test(v) ||
              'ต้องระบุหัสผ่านให้มีภาษาอังกฤษกับตัวเลขปนกัน'; } ],"type":_vm.showConfirmNewPassword ? 'text' : 'password'},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showConfirmNewPassword)?_c('v-img',{staticClass:"pt-2",attrs:{"src":require("@/assets/ic_eye_on.svg"),"contain":"","width":"26"},on:{"click":function($event){_vm.showConfirmNewPassword = false}}}):_c('v-img',{staticClass:"pt-2",attrs:{"src":require("@/assets/ic_eye_off.svg"),"contain":"","width":"26"},on:{"click":function($event){_vm.showConfirmNewPassword = true}}})]},proxy:true}]),model:{value:(_vm.confirmNewPassword),callback:function ($$v) {_vm.confirmNewPassword=$$v},expression:"confirmNewPassword"}})],1)],1),_c('v-row',{staticClass:"my-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mx-auto d-flex justify-center",attrs:{"cols":"12","sm":"8"}},[_c('v-btn',{staticClass:"mr-4 my-2 px-8 py-6 b-r-10",attrs:{"color":"app-disabled-button-theme","dark":""},on:{"click":_vm.clearInput}},[_vm._v("ยกเลิก")]),_c('v-btn',{staticClass:"my-2 px-8 py-6 b-r-10",attrs:{"color":"app-theme","dark":"","type":"submit"}},[_vm._v("บันทึก")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }