import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import Login from "../views/auth/Login.vue";

import UserProfile from "../views/profile/Profile.vue";
import UserChangePassword from "../views/profile/ChangePassword.vue";

import Inventory from "../views/inventory/Index.vue";
import DRP from "../views/dealer_return_rematching_parking_report/Index.vue";
import Stock from "../views/stock/Index.vue";
import Car from "../views/car/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/user/management",
    name: "User",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "profile",
        name: "User.Profile",
        component: UserProfile,
      },
      {
        path: "change_password",
        name: "User.ChangePassword",
        component: UserChangePassword,
      },
    ],
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "Inventory.Index",
        component: Inventory,
      },
    ],
  },
  {
    path: "/dealer_return_rematching_parking_report",
    name: "DRP",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "DRP.Index",
        component: DRP,
      },
    ],
  },
  {
    path: "/stock_report",
    name: "Stock",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "Stock.Index",
        component: Stock,
      },
    ],
  },
  {
    path: "/car_detail",
    name: "Car",
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "",
        name: "Car.Index",
        component: Car,
      },
    ],
  },
  {
    path: "/abort",
    name: "Abort",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Abort.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (
    store.getters.isLoggedIn &&
    ["Login", "ForgotPassword", "ResetPassword"].includes(to.name)
  ) {
    next(false);
    return;
  } else {
    next();
  }
});

export default router;
